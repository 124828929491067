import HttpRequest from "@/http/httpClass";
import { defineStore } from "pinia";
import type { Restaurant } from "./Restaurant";
import { ref } from "vue";

export const useRestaurantsStore = defineStore('restaurants', () => {
  const userRestaurants = ref<Restaurant[]>([])

  const fetchUserRestaurants = async () => {
    if (!userRestaurants.value.length) {
      const request = new HttpRequest(
        '/v2/restaurant?forUser=true',
        'GET',
        true
      )
  
      userRestaurants.value = await request.send()
    }
  }

  return { userRestaurants, fetchUserRestaurants }
})
