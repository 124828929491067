import HttpRequest from '@/http/httpClass';

import { METRICS } from '@/http/endpoints';
import { Actions } from '@/store/v1/store';

const actions: Pick<
  Actions,
  | 'getUserRestaurantSellersBreakdown'
  | 'getUserRestaurantOverallMetrics'
  | 'getUseOverallMetrics'
> = {
  getUserRestaurantSellersBreakdown(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.CATEGORIES_BREAKDOWN({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUseOverallMetrics(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.HEADLINES({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUserRestaurantOverallMetrics(_, options) {
    const { fromDate, toDate } = options;
    const request = new HttpRequest(
      METRICS.RESTAURANT_OVERVIEW({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
      }),
      'GET',
      true
    );

    return request.send();
  },
};

export default actions;
