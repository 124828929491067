<template>
  <div class="date-picker" @click="handleDatePickerClick">
    <label class="date-picker__label" for="date-picker"> {{ $t('datepicker.label') }}: </label>
    <div class="date-picker__input-wrapper" @click="togglePicker">
      <input
        id="date-picker"
        class="date-picker__input"
        v-model="selectedDate"
        autocomplete="off"
      />
      <div v-if="isLoading" class="date-picker__loading">
        <LoaderComponent isMini />
      </div>
      <div v-else class="date-picker__chevron-icon">
        <ChevronIcon />
      </div>
    </div>

    <div v-if="selectableRange && shouldShowPicker" class="date-picker__calendar">
      <Calendar
        :highlight-dates="highlightDates"
        :selectable-range="selectableRange"
        :is-range-calendar="isRangeCalendar"
        :is-sales-calendar="isSalesCalendar"
        :is-inside-modal="isInsideModal"
        :allow-only-forward="allowOnlyForward"
        @close="closePicker"
      ></Calendar>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Calendar from './Calendar.vue';
import { DateRange } from '@/store/v1/store';
import { LoaderComponent } from '@pleez-tech/fe-components-library';

export default defineComponent({
  components: {
    Calendar,
    LoaderComponent,
  },
  props: {
    isRangeCalendar: {
      type: Boolean,
      default: false,
    },
    isSalesCalendar: {
      type: Boolean,
      default: false,
    },
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    allowOnlyForward: {
      type: Boolean,
      default: false,
    },
    initialDate: {
      type: String,
      required: false,
    },
    highlightDates: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      shouldShowPicker: false,
      selectedDate: undefined as string | undefined,
      selectableRange: null as DateRange | null,
    };
  },
  mounted() {
    if (this.initialDate) {
      this.setInitialDate(this.initialDate);
    }
  },
  computed: {
    isLoading() {
      return Boolean(!this.selectableRange);
    },
  },
  methods: {
    setDate(initialDate: string | DateRange) {
      let date: DateRange;

      if (typeof initialDate === 'string') {
        date = { fromDate: this.initialDate!, toDate: '' };
      } else {
        date = initialDate;
      }

      if (this.isRangeCalendar) {
        this.selectableRange = { toDate: date.toDate, fromDate: date.fromDate };
        this.selectedDate = `${date.fromDate.replaceAll('-', '/')} - ${date.toDate.replaceAll(
          '-',
          '/',
        )}`;
      } else if (date.fromDate) {
        this.selectableRange = { toDate: '', fromDate: date.fromDate };
        this.selectedDate = date.fromDate.replaceAll('-', '/');
      }
    },
    togglePicker() {
      this.shouldShowPicker = !this.shouldShowPicker;

      if (this.shouldShowPicker && this.isRangeCalendar) {
        window.addEventListener('click', this.closePicker.bind(undefined, undefined));
      }
    },
    closePicker(selectedDate: DateRange | undefined = undefined) {
      this.shouldShowPicker = false;

      if (selectedDate && this.isRangeCalendar) {
        const { fromDate, toDate } = selectedDate;
        const visualDateString = `${fromDate.replaceAll('-', '/')} - ${toDate.replaceAll(
          '-',
          '/',
        )}`;

        if (visualDateString !== this.selectedDate) {
          this.selectedDate = visualDateString;

          this.$emit('handleRangeDateSelected', this.selectedDate);
          this.setDate({ fromDate, toDate });
        }
      } else if (selectedDate) {
        const { fromDate } = selectedDate;

        this.selectedDate = fromDate.replaceAll('-', '/');
        this.selectableRange = { toDate: '', fromDate: fromDate };

        this.handleDateSelected(fromDate);
      }
    },
    handleDateSelected(date: string) {
      this.$emit('handleDateSelected', date);
    },
    handleDatePickerClick(event: MouseEvent) {
      event.stopPropagation();
    },
    setInitialDate(initialDate: string) {
      if (this.isRangeCalendar) {
        const [fromDate, toDate] = initialDate.split(' - ');
        this.setDate({ fromDate, toDate });
      } else {
        this.setDate(initialDate);
      }
    },
  },
  watch: {
    shouldShowPicker(shouldShow) {
      if (!shouldShow) {
        window.removeEventListener('click', this.closePicker.bind(undefined, undefined));
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.date-picker {
  --date-picker-min-width: 328px;

  position: relative;
  min-width: var(--date-picker-min-width);

  &__label {
    @include brand-font-xm;

    display: block;
    margin-bottom: var(--spacing-xxs);

    color: var(--text-color);
  }
  &__input-wrapper {
    position: relative;

    display: flex;
    align-items: center;
  }
  &__input {
    flex: 1;
    height: var(--input-height);

    padding-left: var(--spacing-xs);
    margin: 0;

    border: 1px solid var(--grey-lines);
    border-radius: var(--spacing-xxs);

    color: var(--text-color);

    font-size: 16px;

    &:focus-visible {
      outline: 1px solid var(--input-border);
    }
  }
  &__calendar {
    position: absolute;
    width: 100%;

    background-color: var(--color-white);

    z-index: var(--z-index-datepicker, 6);
  }
  &__loading,
  &__chevron-icon {
    position: absolute;
    right: var(--spacing-xs);

    width: fit-content;

    transform: translateX(calc(-1 * var(--spacing-xs)));
  }
  &__chevron-icon {
    transform: rotate(270deg);

    stroke: var(--brand-black);
    stroke-width: 2px;
    fill: var(--brand-primary);
  }
}
</style>
