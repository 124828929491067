<!--Select component that wraps the v-select external
  library.
  
  isClearable: Can the user clear the selected property?
  isSelectable: Picked a value won't show on the input. Label is shown instead
  hasSearch: Disables search

-->
<template>
  <div
    :class="[
      'select',
      {
        'select--with-select-all': hasSelectAll,
      },
    ]"
  >
    <label
      :for="`${label}-select-${componentUid}`"
      :class="[isLabelVisible ? 'select__label' : 'select__visually-hidden']"
      >{{ `${label}:` }}
    </label>
    <div class="select__wrapper">
      <span class="select__icon" aria-hidden>
        <slot />
      </span>
      <v-select
        :input-id="`${label}-select-${componentUid}`"
        :options="paginated"
        :placeholder="selectPlaceHolder"
        :class="[
          'select__element',
          {
            'select__element--with-icon': hasSelectIcon,
          },
        ]"
        :label="displayKey"
        :getOptionKey="generateFilterKey"
        :multiple="multiple"
        :autoscroll="!multiple"
        :deselectFromDropdown="multiple"
        :closeOnSelect="!multiple"
        :clearable="isClearable"
        :taggable="allowNewOptions"
        :disabled="disabled"
        :searchable="hasSearch"
        :loading="isLoading"
        :filter="searchParser"
        :clear-search-on-select="!multiple"
        v-model="selected"
        @open="handleOnOpen"
        @option:selecting="handleOptionSelecting"
        @option:selected="handleOptionSelected"
        @option:deselected="handleOptionDeselected"
        @close="handleOnClose"
        @search="handleSearch"
        @option:created="handleNewOption"
        @change.stop
      >
        <template #option="option">
          <div
            class="select__option-wrapper"
            :class="{
              'select__option-wrapper--multiline': customBodyIsMultiLines || multiple,
            }"
          >
            <slot v-if="hasCustomLabel" name="custom-label" :value="option" />

            <div
              v-else
              class="select__option-wrapper--label"
              :class="{
                'select__option-wrapper--label--option-with-secondary': secondaryKey,
              }"
            >
              <div v-if="multiple" class="select__multiple">
                <CheckBox v-if="multiple" />
              </div>
              <p
                class="select__default-label"
                v-if="option[displayKey ? displayKey : label] && !hasCustomLabel"
              >
                {{ option[displayKey ? displayKey : label] }}
              </p>
            </div>
            <p class="select__option-wrapper--description" v-if="secondaryKey && !hasCustomLabel">
              {{ option[secondaryKey] }}
            </p>
          </div>
        </template>

        <template #selected-option="option">
          <slot name="custom-selected-option" :value="option" />
        </template>

        <template #selected-option-container="{ option, multiple }">
          <div v-if="multiple">
            <!--Only show first option-->
            <template v-if="isOptionAtIndex(option[displayKey || 'label'], 0) && !currentSearch">
              {{ visuallySelectedOption(option) }}
            </template>
          </div>
        </template>

        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="select__element--loading">
            {{ $t('shared.inputs.lazyListHelper') }}
          </li>

          <slot name="custom-footer" />
        </template>

        <template #no-options>
          <li v-if="!hasCustomFooter">
            {{ $t('shared.inputs.noItems') }}
          </li>

          <li>
            <slot name="custom-no-options" />
          </li>
        </template>

        <template #open-indicator>
          <Chevron v-if="!hideActions" class="vs__open-indicator" />
          <span v-else />
        </template>
      </v-select>
      <span v-if="multiple" class="select__multiple-selection-count">
        {{ multipleSelectionCount }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, getCurrentInstance, toRaw } from 'vue';
import CheckBox from '@/components/Inputs/Checkbox.vue';
import Chevron from '@/assets/icons/chevron.svg?component';

import { SelectData, SelectProps } from './Inputs';

export default defineComponent({
  name: 'Select',
  components: {
    CheckBox,
    Chevron,
  },
  emits: {
    change: (values: SelectProps['values']) => true,
    search: (searchQuery: string) => true,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    values: {
      type: Array as PropType<SelectProps['values']>,
      default: () => [],
    },
    filterKey: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayKey: {
      type: String,
      default: 'label',
    },
    secondaryKey: {
      type: String,
      required: false,
    },
    allowNewOptions: {
      type: Boolean,
      required: false,
    },
    defaultValue: {
      type: [String, Object, Array] as PropType<SelectProps['defaultValue']>,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLabelVisible: {
      type: Boolean,
      default: false,
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
    instantFeedback: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    clearOnSearch: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(
      this.infiniteScroll as unknown as IntersectionObserverCallback,
    );
  },
  data() {
    return {
      selected: [],
      currentSearch: '',
      selectAllLabel: this.$t('filters.selectAll'),
      itemLimit: 20,
      observer: null,
      isSelectAll: false,
      isSimpleDropDown: false,
      dropDownListElement: null,
    } as SelectData;
  },
  computed: {
    componentUid() {
      const instance = getCurrentInstance();
      return instance?.uid;
    },
    hasCustomLabel() {
      return Boolean(this.$slots['custom-label']);
    },
    customBodyIsMultiLines() {
      try {
        let customLabel = this.$slots['custom-label'] ? this.$slots['custom-label']({}) : [];

        if (!customLabel) {
          customLabel = [];
        }

        return customLabel.length > 1;
      } catch {
        return false;
      }
    },
    hasSelectIcon() {
      const slots = this.$slots.default;

      return Boolean(slots);
    },
    hasCustomFooter() {
      return Boolean(this.$slots['custom-no-options']);
    },
    cleanedValues() {
      return this.values.filter(Boolean);
    },
    filtered() {
      if (!this.isSearchable) {
        return this.cleanedValues;
      }
      const lowercaseSearch = this.removeAccents(this.currentSearch.toLowerCase());
      const filteredValues = this.cleanedValues.filter((value) => {
        if (typeof value === 'string') {
          const lowercaseValue = this.removeAccents(value.toLowerCase());
          return lowercaseValue.includes(lowercaseSearch);
        } else if (this.displayKey) {
          const lowercaseValue = this.removeAccents(value[this.displayKey].toLowerCase());
          return lowercaseValue.includes(lowercaseSearch);
        }
        return false;
      });

      if (this.hasSelectAll) {
        filteredValues.unshift(this.selectAllValue);
      }

      return filteredValues;
    },
    paginated() {
      return this.filtered.slice(0, this.itemLimit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
    currentLanguage() {
      return this.$store.state.selectedLanguage;
    },
    multipleSelectionCount() {
      const selectedLength = this.selected.length;

      if (selectedLength > 1) {
        return this.multiple && !this.isSelectAll ? `+${selectedLength - 1}` : '';
      }

      return '';
    },
    selectPlaceHolder() {
      return this.placeholder ? this.placeholder : this.label;
    },
    selectAllValue() {
      const valuesPropertyName = this.displayKey ? this.displayKey : 'label';

      this.isSimpleDropDown = typeof this.values[0] === 'string';

      return this.isSimpleDropDown
        ? this.selectAllLabel
        : { [valuesPropertyName]: this.selectAllLabel, noCustomStyle: true };
    },
    selectAllKey() {
      return this.displayKey ? this.displayKey : 'label';
    },
  },
  methods: {
    removeAccents(str: string) {
      return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    },
    handleOnOpen() {
      this.$nextTick(async () => {
        const loadElement = this.$refs.load as HTMLElement;
        if (this.hasNextPage) {
          await this.$nextTick();
          this.observer?.observe(loadElement);
        }
      });
    },
    async infiniteScroll(data: { isIntersecting: boolean; target: HTMLElement }[]) {
      const [{ isIntersecting, target }] = data;
      if (isIntersecting) {
        const ul = target.offsetParent as HTMLElement;
        const scrollTop = ul.scrollTop;

        this.itemLimit += 50;

        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    searchParser(options: Record<string, unknown>[], search: string) {
      if (!this.isSearchable) {
        return options;
      }
      return options.filter((option: Record<string, unknown>) => {
        let label = option[this.displayKey || 'label'];

        if (typeof label === 'number') {
          label = label.toString();
        }

        if (!label) {
          label = option;
        }

        return this.removeAccents((label as string).toLocaleLowerCase() || '').includes(
          this.removeAccents(search).toLocaleLowerCase(),
        );
      });
    },
    handleOnClose() {
      this.observer?.disconnect();

      if (this.selected === null) {
        // If all selected values are removed, selected becomes null
        if (this.defaultValue) {
          // To avoid errors, we set value to default if empty
          if (Array.isArray(this.defaultValue)) {
            this.selected = [...this.defaultValue];
          } else {
            this.selected = [this.defaultValue];
          }
        } else {
          // If no default exists, set to empty array
          this.selected = [];
        }
      } else if (!Array.isArray(this.selected)) {
        // Make return value always an array
        this.selected = [this.selected];
      }

      this.$emit('change', this.isSelectAll ? this.cleanedValues : this.selected);

      if (!this.isSelectable) {
        this.selected = this.placeholder ? [this.placeholder] : [];
      }
    },
    handleNewOption(newOption: Record<string, unknown>) {
      this.values.push(newOption);
    },
    handleSearch(searchQuery: string) {
      const selectedValue = this.selected;
      // Clear selected values if search is empty,
      // this helps because the user can see the selected value,
      // while typing so we temporary clear the input
      if (this.clearOnSearch && selectedValue.length > 0) {
        this.selected = [];

        // After finishing of selecting something, we set back the selected value
        if (searchQuery === '') {
          this.selected = selectedValue;
        }
      }
      this.currentSearch = this.removeAccents(searchQuery.toLowerCase());
      this.$emit('search', searchQuery);
    },
    handleOptionSelecting(selectedOption: Record<string, unknown>) {
      if (this.hasSelectAll) {
        const optionLabel = this.isSimpleDropDown
          ? selectedOption
          : selectedOption[this.selectAllKey];

        this.isSelectAll = optionLabel === this.selectAllLabel;
      }
    },
    handleOptionSelected() {
      if (this.isSelectAll) {
        const valuesToAdd = this.cleanedValues.map((it) => toRaw(it));

        this.selected = valuesToAdd;
        this.selected.unshift(this.selectAllValue);
      } else if (!Array.isArray(this.selected)) {
        // Make return value always an array
        this.selected = [this.selected];
      }

      if (this.instantFeedback) {
        this.$emit('change', this.isSelectAll ? this.cleanedValues : this.selected);
      }
    },
    /**
     * Selected All value is always at the bottom of the array
     * if it was clicked, deselect all values
     * if another value was clicked, deselect only the 'select all'
     */
    handleOptionDeselected(deselectedOption: Record<string, unknown>) {
      if (this.multiple) {
        const isSelectAllChecked = this.isOptionAtIndex(this.selectAllLabel, 0);
        const optionLabel = this.isSimpleDropDown
          ? deselectedOption
          : deselectedOption[this.selectAllKey];

        if (this.isSelectAll && optionLabel === this.selectAllLabel) {
          this.selected = [];
        } else if (isSelectAllChecked) {
          this.selected.shift();
        }

        this.isSelectAll = false;
      }

      if (this.instantFeedback) {
        this.$emit('change', this.selected);
      }
    },
    generateFilterKey(option: Record<string, unknown>) {
      if (this.filterKey) {
        return option[this.filterKey];
      } else {
        return JSON.stringify(option);
      }
    },
    isOptionAtIndex(optionName: string, index: number) {
      if (this.selected.length !== 0) {
        const selectedValueAtIndex = this.selected[index];

        if (typeof selectedValueAtIndex === 'string') {
          return optionName === selectedValueAtIndex;
        }

        /**
         * I added this, because it was giving errors when the endpoint
         * that fills the selectors was returning empty values,
         * which is not supposed to happen, but it was happening.
         * So its just a safety measure.
         */
        if (
          typeof selectedValueAtIndex === 'object' &&
          !Array.isArray(selectedValueAtIndex) &&
          (this.displayKey ?? 'label') in selectedValueAtIndex
        ) {
          return optionName === selectedValueAtIndex[this.displayKey || 'label'];
        }

        return optionName === selectedValueAtIndex;
      }

      return false;
    },
    visuallySelectedOption(option: Record<string, unknown>) {
      if (option[this.selectAllKey] === this.selectAllLabel) {
        return this.$t('filters.selectAllLabel');
      }

      return option[this.selectAllKey];
    },
  },
  watch: {
    '$store.state.selectedLanguage'() {
      this.selectAllLabel = this.$t('filters.selectAll');
    },
    disabled: {
      handler(isDisable) {
        if (isDisable) {
          this.selected = [];
        }
      },
    },
    defaultValue: {
      handler(defaultValue) {
        if (this.multiple && defaultValue) {
          if (Array.isArray(defaultValue)) {
            this.selected = [...defaultValue];
          } else {
            this.selected = [defaultValue];
          }

          if (
            this.hasSelectAll &&
            this.selected.length > 0 &&
            this.selected.length === this.cleanedValues.length
          ) {
            this.selected.unshift(this.selectAllValue);
            this.isSelectAll = true;
          } else {
            this.isSelectAll = false;
          }
        } else {
          this.selected = defaultValue ?? [];
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.select {
  position: relative;

  :deep() {
    --vs-actions-padding: 2px 16px 0px 3px;
    --vs-border-radius: var(--spacing-xxs);
    --vs-controls-color: transparent;
    --vs-search-input-color: var(--secondary-text);
    --vs-search-input-placeholder-color: var(--secondary-text);

    --vs-dropdown-color: var(--secondary-text);
    --vs-dropdown-option--active-bg: var(--brand-background);
    --vs-dropdown-option-padding: var(--spacing-xxs) var(--spacing-xs);
    --vs-dropdown-max-height: 24rem;

    --vs-dropdown-option--active-color: var(--brand-secondary);
    --vs-dropdown-option--deselect-bg: var(--brand-background);

    --vs-selected-bg: var(--grey-background);
    --vs-selected-border-color: var(--grey-lines);
    --vs-selected-border-style: solid;
    --vs-selected-border-width: 1px;

    .vs__search {
      margin: 0;
      padding: 0;
    }
    .vs__dropdown-menu {
      padding: 0;
    }
    .vs__dropdown-toggle {
      min-width: 180px;
      min-height: var(--input-height);
      max-height: var(--input-height);
      padding: 0;

      overflow: clip;
    }
    .vs__clear {
      display: none;
    }
    .vs__open-indicator {
      stroke: var(--brand-black);
      stroke-width: 2px;
      height: 1.5rem;
    }
    .vs--open .vs__open-indicator {
      transform: rotate(180deg) scale(var(--vs-controls-size));
    }
    .vs__dropdown-option {
      text-transform: capitalize;

      &:hover {
        color: var(--brand-secondary);
        background-color: var(--brand-background);
      }
      &:first-of-type {
        padding-top: var(--spacing-xs);
      }

      .select__default-label {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: var(--spacing-3xs);
        padding-bottom: var(--spacing-3xs);
      }

      .select__option-wrapper {
        display: block;

        &--label {
          display: flex;
          flex-direction: row;
          gap: var(--spacing-xxs) 8px;
          align-items: center;

          &--option-with-secondary {
            @include font-t2-semibold;
          }
        }

        &--description {
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 100;
        }
        &--multiline {
          align-items: center;
          grid-template-columns: 24px auto;
          column-gap: var(--spacing-xxs);
          row-gap: var(--spacing-xxs);
        }
      }

      &--selected {
        color: var(--brand-secondary);
        background-color: var(--brand-background);

        .select__multiple {
          .checkbox__input {
            background-color: var(--brand-background);
            border-color: var(--brand-secondary);

            &:before {
              transform: scale(1);
            }
          }
        }

        &.vs__dropdown-option--highlight {
          color: var(--brand-secondary);
          background-color: rgba(var(--brand-background-rgb), 0.5);
        }
      }
    }
    .vs__selected-options {
      flex-wrap: unset;
      align-items: center;

      /**
      *  For the +1 on multiple selector
      */
      margin: 0 var(--spacing-xs);

      text-transform: capitalize;

      .vs__selected {
        @include add-ellipsis;
      }
    }
  }

  &__wrapper {
    position: relative;
  }
  &__multiple-option {
    display: flex;
    align-items: center;

    &--custom {
      margin-top: var(--spacing-3xs);
      padding-bottom: var(--spacing-3xs);
    }
  }
  &__label {
    @include brand-font-xm;

    display: block;
    margin-bottom: var(--spacing-xxs);

    color: var(--text-color);
  }
  &__element {
    min-width: 160px;

    &--with-icon {
      :deep() .vs__selected-options {
        margin-left: var(--input-height);
      }
    }
    &--loading {
      color: var(--secondary-text-2);

      font-size: 0.9;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: var(--spacing-xs);
    height: 1.5rem;
    width: 1.5rem;
    transform: translateY(-50%);

    stroke: var(--brand-primary);

    z-index: 1;
  }
  &__visually-hidden {
    @include visually-hidden;
  }
  &__multiple-selection-count {
    position: absolute;

    top: 50%;
    right: var(--spacing-l);

    transform: translateY(-50%);

    color: var(--brand-secondary);
  }
  &--with-divider {
    :deep() .vs__dropdown-option {
      position: relative;

      &:after {
        position: absolute;
        right: var(--spacing-xs);
        bottom: 0;
        left: var(--spacing-xs);

        border-bottom: 1px solid var(--grey-lines);

        content: '';
      }
    }
  }
  &--checkmark {
    :deep() .vs__dropdown-option--selected {
      display: flex;
      align-items: center;

      &:before {
        width: var(--spacing-xs);
        aspect-ratio: 1;
        margin-right: var(--spacing-xxs);
        background-color: var(--brand-secondary);
        clip-path: var(--checkmark-clip-path);

        content: '';
      }
    }
  }
  &--with-select-all {
    :deep() .vs__dropdown-option:first-of-type {
      position: relative;
      margin-bottom: var(--spacing-3xs);
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xxs);

      &.vs__dropdown-option--selected {
        margin-bottom: 0;

        .select__multiple .checkbox__input {
          background-color: var(--brand-secondary);

          &:before {
            background-color: var(--color-white);
          }
        }
      }

      &:after {
        position: absolute;
        right: var(--spacing-xs);
        bottom: 0;
        left: var(--spacing-xs);

        border-bottom: 1px solid var(--grey-lines);

        content: '';
      }
    }
  }
}
</style>
