<template>
  <header class="header">
    <div class="header__temp-mobile">
      <MenuIcon class="header__mobile-menu-icon" @click="$emit('menuClick')" />
      <router-link class="header__logo" to="/home">
        <PleezLogo class="header__logo" />
      </router-link>
    </div>
    <div class="header__actions">
      <Select
        class="header__button header__account-button"
        :values="accountDropdownOptions"
        :label="accountHolder"
        :has-search="false"
        :is-selectable="false"
        :is-clearable="false"
        @change="handleAccountOptionClick"
      >
        <div class="header__action-icon">
          <ProfileIcon />
        </div>

        <template #custom-label="{ value }">
          <div
            class="header__account-option"
            :class="{
              'header__account-option--small': value.label.length >= 25,
            }"
          >
            <component :is="value.icon" class="header__option-icon" />
            <p>{{ $t(value.label) }}</p>
          </div>
        </template>

        <template #custom-footer>
          <span class="header__flags">
            <span class="fi fi-pt" @click="setupLanguage('pt-PT')"></span>
            <span class="fi fi-gb" @click="setupLanguage('en-GB')"></span>
            <span class="fi fi-es" @click="setupLanguage('es-ES')"></span>
          </span>
        </template>
      </Select>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FiltersComponent from '@/components/Filters/FiltersComponent.vue';
import Select from '@/components/Inputs/Select.vue';

import { accountDropdownOptions } from './Header.config';
import { HeaderData, AccountOption } from './Header';
import { MenuIcon } from '@pleez-tech/fe-components-library';

import { setupLanguage } from '@/plugins/i18n';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Session } from '@/globals';

export default defineComponent({
  components: {
    Select,
    FiltersComponent,
    MenuIcon,
  },
  data() {
    return {
      accountDropdownOptions: accountDropdownOptions,
    } as HeaderData;
  },
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    accountHolder() {
      const token = Session.getSession('pleez-token');
      const tokenPayload = jwtDecode(token!) as JwtPayload & { email: string };
      return tokenPayload.email;
    },
  },
  methods: {
    handleAccountOptionClick(option: AccountOption[]) {
      const value = option[0]?.value;

      switch (value) {
        case 'logout':
          this.handleLogout();
          break;
        case 'settings':
          this.handleSettings();
          break;
        case 'businessSettings':
          this.handleBusinessSettings();
        default:
          break;
      }
    },
    handleLogout() {
      this.$router.push('/logout');
    },
    handleSettings() {
      this.$router.push('/settings/account');
    },
    handleBusinessSettings() {
      this.$router.push('/settings/business');
    },
    setupLanguage,
  },
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
  min-height: var(--header-height);

  padding: var(--spacing-s);

  background-color: var(--brand-grey);

  box-sizing: border-box;

  &__mobile-menu-icon {
    margin-right: 16px;
  }
  &__actions {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
  &__option-icon {
    flex-shrink: 0;
    margin-right: 8px;
    stroke: var(--brand-primary);
  }
  &__modal {
    position: absolute;
  }
  &__flags {
    display: flex;
    margin: 16px 0 8px 24px;

    & > .fi {
      margin-right: 8px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__button {
    width: 100%;
    height: 100%;

    &:after {
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 4px;

      width: calc(46px - 4px); //minus left
      height: calc(100% - 8px); //minus top and bottom

      border-radius: 6px;

      opacity: 0.5;

      content: '';
    }
  }
  &__account-button {
    &:after {
      background-color: #faf5ff;
    }

    .header__action-icon {
      stroke: var(--brand-secondary);
    }

    :deep() .vs__selected-options {
      margin-left: calc(var(--input-height) + 8px);
    }
  }
  &__account-option {
    display: flex;
    align-items: center;

    &--small {
      font-size: 0.9rem;
    }
  }

  &__temp-mobile {
    display: flex;
    align-items: center;
  }

  @include breakpoint-from('smallDesktop') {
    flex-direction: row;

    padding: 16px 32px;

    &__mobile-menu-icon {
      display: none;
    }
    &__actions {
      flex-direction: row;

      width: fit-content;
    }
    &__account-button {
      width: 258px;

      margin-left: 16px;
    }
  }
}
</style>
