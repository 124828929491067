/**
 * Request is an abstract class to make HTTTP/HTTPS requests
 * @constructor
 */
import { localizeTemplate as $t } from '@/plugins/i18n/index';

import { routerInstance } from '@/routes/router';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

import { Session } from '@/globals';
import translations from '@/plugins/i18n/translations.json';
import { LoginResponse } from './endpoints.types';
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

class HttpRequest {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers: Headers;
  baseURL: string | undefined;
  body = '';

  private static _instance: AxiosInstance | undefined;

  // Deprecated
  constructor(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    isAuthenticated: boolean,
    body?: any,
  ) {
    this.baseURL = process.env.VUE_APP_API_BASE_URL;
    this.url = url;
    this.method = method;
    this.headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });

    if (isAuthenticated) {
      const token = Session.getSession('api-key')
        ? Session.getSession('api-key')
        : Session.getSession('pleez-token');
      const tokenType = Session.getSession('api-key') ? 'API-KEY' : 'Bearer';

      this.headers.append('Authorization', `${tokenType} ${token}`);
    }

    if (body) {
      this.body = JSON.stringify(body);
    }
  }

  // Deprecated
  send<T>(): Promise<T> {
    return new Promise((resolve, reject) => {
      const finalURL = this.baseURL + this.url;
      const requestOptions: RequestInit = {
        method: this.method,
        headers: this.headers,
        body: this.body || null,
        redirect: 'follow',
      };

      fetch(finalURL, requestOptions)
        .then(async (response) => {
          if (response.status > 399) {
            throw {
              statusText: response.statusText,
              status: response.status,
              body: await response.json(),
            };
          }
          return response?.text() || {};
        })
        .then((result) => {
          try {
            resolve(result ? JSON.parse(result) : {});
          } catch (err) {
            if (result === 'OK' || result === 'Created') {
              resolve({
                message: 'OK',
              } as T);
            } else {
              console.log(err);

              throw translations.errors.defaultHttpError['en-GB'];
            }
          }
        })
        .catch((error) => {
          /**
           * Should be revised when unauthorized resources start being used
           */
          if (
            !(error.body?.message === 'Username or Password Invalid') &&
            (error.status === 403 || error.status === 401)
          ) {
            if (routerInstance.currentRoute.value.path !== '/') {
              $toast.error($t('errors.sessionExpired'));

              setTimeout(() => routerInstance.push('/logout'), 1000);

              reject();
            }
            $toast.info($t('warnings.loginRequest'), { duration: 1000 });

            resolve({} as T);
          } else {
            console.error(error);

            reject({
              error: error,
              url: this.url,
              requestOptions: requestOptions,
            });
          }
        });
    });
  }

  // Deprecated
  static async authenticate(data: string): Promise<LoginResponse> {
    const url = new URL('auth', process.env.VUE_APP_API_BASE_URL!);

    const response = await axios.post<LoginResponse>(url.toString(), { data });

    return response.data;
  }

  private static get instance() {
    if (!this._instance) {
      let auth: string;

      if (Session.getSession('api-key')) {
        auth = `API-KEY ${Session.getSession('api-key')}`;
      } else {
        auth = `Bearer ${Session.getSession('pleez-token')}`;
      }
      this._instance = axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL,
        headers: {
          Authorization: auth,
        },
      });
    }
    return this._instance;
  }

  static async get<T>(url: string, options: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return HttpRequest.instance.get<T>(url, options);
  }

  static async post<T>(
    url: string,
    data: any,
    options: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return HttpRequest.instance.post<T>(url, data, options);
  }

  static async put<T>(
    url: string,
    data: any,
    options: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return HttpRequest.instance.put<T>(url, data, options);
  }

  static async delete<T>(url: string, options: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return HttpRequest.instance.delete<T>(url, options);
  }

  static async patch<T>(
    url: string,
    data: any,
    options: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return HttpRequest.instance.patch<T>(url, data, options);
  }
}

export default HttpRequest;
